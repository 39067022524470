@media all {
  .page-break {
    display: none;
  }
  .a4 {
    background: white;
    width: 21cm;
    //height: 29.7cm;
    display: block;
    margin: 0 auto 0.5cm;
    padding: 10px 25px;
    //box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    //overflow-y: scroll;
    box-sizing: border-box;
    font-size: 12pt;
    position: relative;
    .fontWeight {
      font-weight: 700;
    }
  }

  .lSpacing {
    letter-spacing: 0.025em;
  }
  .marginBot {
    margin-bottom: 20px;
  }

  .logo_print {
    top: -5px;
    z-index: 1;
    right: -10px;
  }

  .page-hidden {
    display: none;
  }
}

@page {
  size: auto;
  margin: 16mm;
}

@media print {
  .printElement {
    display: block;
  }
  .a4 {
    all: unset;
    .fontWeight {
      font-weight: 700;
    }
  }
  .marginBot {
    all: unset;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
  .logo_print {
    position: fixed;
    display: block;
    page-break-before: always;
  }
  .page-hidden {
    display: block;
  }
  .printElement1 {
    padding-right: 50px;
  }
}