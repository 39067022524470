.fade img {
    animation: imgAnim 2s cubic-bezier(0.37, 0, 0.63, 1);
}

@keyframes imgAnim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slide {
    animation: imgAnim 1s cubic-bezier(0.37, 0, 0.63, 1) 0s 1 alternate forwards;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes shrink {
    0% {
        width: 95%;
    }
    100% {
        width: 90%;
    }
}
