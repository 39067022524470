.accordion-item {
    $self: &;

    &--opened {
        #{ $self }__inner {
            max-height: 800rem;
            transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
            transition-duration: 0.7s;
            transition-property: max-height;
        }

        #{ $self }__content {
            opacity: 1;
            transform: translateY(0);
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            transition-duration: 0.5s;
            transition-delay: 0.1s;
            transition-property: opacity, transform;
        }
    }

    &__inner {
        text-transform: cubic-bezier(1, 0.5, 0.8, 1);
        transition-duration: 0.7s;
        transition-property: max-height;
    }

    &__content {
        transition-timing-function: linear, ease;
        transition-duration: 0.5s;
        transition-property: opacity, transform;
        transition-delay: 0.1s;
        transform: translate3d(0, -100%, 0);
    }
}
