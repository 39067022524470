@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@styles/fonts.scss";
@import url('https://fonts.cdnfonts.com/css/omnes?styles=15970');
@import "@styles/animations.scss";
@import "@styles/accordion.scss";
@import "@styles/input.scss";
@import "@styles/modal.scss";
@import "@styles/pdf.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @apply tracking-wide;
}

body {
  height: 100%;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden;
  font-family: "Omnes", sans-serif;
  font-size: 16px;
  line-height: 1.45em;
  color: #1a1a1f;
}

html,
#root,
.fill {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

h1 {
  font-size: 70px;
  line-height: 76px;
  @media only screen and (max-width: 1024px) {
    font-size: 56px;
    line-height: 58px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 42px;
    line-height: 48px;
  }
  @media only screen and (max-width: 426px) {
    font-size: 20px;
    line-height: 26px;
  }
}

h2 {
  font-size: 64px;
  line-height: 89.6px;
  @media only screen and (max-width: 1024px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media only screen and (max-width: 426px) {
    font-size: 20px;
    line-height: 25px;
  }
}

h3 {
  font-size: 35px;
  line-height: 42px;
  @media only screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media only screen and (max-width: 426px) {
    font-size: 20px;
    line-height: 25px;
  }
}

a {
  text-decoration: none;
}

p {
  font-size: 16px;
  margin: 0;
}

span {
  font-size: 12px;
}

.container {
  width: 91.8%;
  margin: 0 auto;
}

.bord {
  border-top: #1a1a1f;
  border-width: 2px;
  opacity: 0.1;
}

.base-border {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid rgba(26, 26, 31, 0.07);
  border-radius: 8px;
}

.form-input {
  background: #ffffff;
  border: 1px solid rgba(26, 26, 31, 0.07);
  border-radius: 8px;
  padding: 8px 16px;
}

#sidebar:hover #icon {
  background: #ff5800;
}

.active-navbar-item {
  color: #ff5800;
  text-underline-offset: 1.4rem;
  text-decoration: underline solid #ff5800 2px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

.activeFooterItem {
  color: #ff5800 !important;
}

#auction-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}