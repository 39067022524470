// radio
input[type="radio"],
label {
  cursor: pointer;
}

input[type="radio"] {
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  margin-right: 0.3rem;
}

input[type="checkbox"],
input[type="checkbox"] {
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  margin-right: 0.3rem;
}

.round::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid rgba(159, 163, 167, 1);
}

.round:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: rgba(255, 88, 0, 1);
  transform: translate(-50%, -50%);
  visibility: visible;
}

.square::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 1.2rem;
  height: 1.2rem;
  color: white;
  border: 1px solid #f35429;
  border-radius: 0 !important;
}

.square:checked:after {
  content: "\2714";
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 3px;
  top: -2px;
  color: #f35429;
}

.square1:before {
  content: "";
  background: url(../assets/icons/check.svg) no-repeat center center;
  object-fit: contain;
  height: 80%;
  width: 80%;
  display: inline-block;
  //font-size: 14px;
  //font-weight: 600;
  position: absolute;
  //left: 1px;
  //top: -5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //color: #f35429;
}


// checkbox
.checkbox {
  width: 100%;
  height: 1rem;
  top: 5px;
  position: absolute;
  opacity: 0;
  z-index: 10;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;
    color: white;
    border: 1px solid #f35429;
    border-radius: 3px;
    z-index: 1;
    margin-top: 5px;
  }

  &:checked + label:before {
    border: 2px solid #f35429;
    background: #ffffff;
  }

  &:checked + label:after {
    content: "\2714";
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    left: 3px;
    top: 1px;
    color: #f35429;
  }
}

// validation

input:invalid:not(:placeholder-shown) {
  border-color: red;
}

// textarea
textarea {
  min-height: 100px;
  max-height: 250px;
}
