.wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: inherit;
    outline: 0;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.44);
    z-index: 500;
}

.modal {
    top: 64px;
    padding: 16px;
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    border-radius: 8px;
}

.header {
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
}
.closeBtn {
    font-size: 1.4rem;
    border: none;
    border-radius: 3px;
    margin-left: 0.5rem;
    background: none;
    :hover {
        cursor: pointer;
    }
}

.content {
    padding: 10px;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
}
