@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($path + ".ttf") format("truetype");
        font-weight: $weight;
        font-style: $style;
    }
}

@include font-face(Omnes, "./../assets/fonts/Omnes_Regular", 400);
@include font-face(Omnes, "./../assets/fonts/Omnes-Semibold", 600);
// @include font-face(Omnes, "./assets/fonts/Omnes_Bold", 800);
